import React from 'react';
import oscarDoorsImage from '../../../Assets/Images/windows/window01.png';
import WindowsGallery from '../../pages/WindowsPage/WindowsGallery';
import './WindowsPage.css'; // Import your CSS file for styling
import CallTodayButton from '../CallToday/CallTodayButton';

const WindowsPage = () => {
  const handleRequestQuote = () => {
    const defaultMessage = encodeURIComponent(
      `Hello Oscar Welding KE,\n\n` +
      `I am interested in your welding services. ` +
      `Could you please provide a quote for the following:\n\n` +
      `- ***type service here***\n\n` +
      `- ***attach design images if any***\n\n` +
      `- \n\n` +
      `This message is being sent from https://oscarweldingke.co.ke/`
    );
  
    const whatsappLink = `https://wa.me/+254707460766/?text=${defaultMessage}`;

    // Open WhatsApp chat in a new tab
    window.open(whatsappLink, '_blank');
  };

  return (
    <div className="windowsContainer">
      <h1>Elevate Your Home with Exquisite Welded Windows</h1>
      <p>At Oscar Welding KE, we're passionate about crafting more than just windows; we create masterpieces that blend functionality with sheer elegance.</p>
      {/* Include the provided content for windows */}
      <div className="windowsContent">
        <img src={oscarDoorsImage} alt="Oscar Welding KE Doors" className="windowsImage" />
        <div className="windowsDescription">
        <p>Each window is meticulously designed and welded with unparalleled precision, ensuring not just a product, but an artistic statement for your home.</p>
        <p>Our welded windows aren't just about aesthetics; they're a fusion of beauty and purpose, seamlessly merging security with stunning design elements.</p>
        <p>We prioritize excellence, utilizing only the finest materials to guarantee durability, resilience, and a timeless appeal that withstands the test of time.</p>
        <p>Your home deserves personalized perfection. From classic designs to modern motifs, we tailor each window to align with your unique style and preferences.</p>
        <p>Beyond Windows: We don’t just create windows; we curate experiences, infusing artistry into every frame and pane to redefine the ambiance of your living space.</p>
        <p className='gateCallofAction'>Ready to adorn your home with windows that transcend the ordinary?</p>
        <p>🌟 ✨ Reach out to us today,  <br />
        <button className="quoteButton" onClick={handleRequestQuote}>
            Request Quote
          </button>.</p>
        </div>
      </div>
      <WindowsGallery />
      <CallTodayButton />
    </div>
  );
};

export default WindowsPage;
