import React from 'react';
import oscarWeldingImage from '../../../Assets/Images/balconygrill.jpg';
import GrillsGallery from '../GrillsPage/GrillsGallery';
import './GrillsPage.css'; // Import your CSS file for styling
import CallTodayButton from '../CallToday/CallTodayButton';

const GrillsPage = () => {
  const handleRequestQuote = () => {
    const defaultMessage = encodeURIComponent(
      `Hello Oscar Welding KE,\n\n` +
      `I am interested in your welding services. ` +
      `Could you please provide a quote for the following:\n\n` +
      `- ***type service here***\n\n` +
      `- ***attach design images if any***\n\n` +
      `- \n\n` +
      `This message is being sent from https://oscarweldingke.co.ke/`
    );
  
    const whatsappLink = `https://wa.me/+254707460766/?text=${defaultMessage}`;

    // Open WhatsApp chat in a new tab
    window.open(whatsappLink, '_blank');
  };

  return (
    <div className="grillsContainer">
      <h1>Elevate Your Space with Exquisite Balcony & Window Grills</h1>
      <p>Welcome to Oscar Welding KE where artistry meets functionality! 🎨✨</p>
      {/* Include the provided content for grills */}
      <div className="grillsContent">
      <img src={oscarWeldingImage} alt="Oscar Welding KE" className="grillImage" />
        <div className="grillsDescription">
        <p>Our bespoke balcony and window grills are more than just barriers; they're stunning pieces of art meticulously crafted to enhance the aesthetic appeal of your home.</p>
        <p>Each design is born from passion and precision, tailored to complement your style, ensuring a seamless blend with your architecture.</p>
        <p>We prioritize durability and quality, using premium materials to guarantee longevity and withstand the test of time.</p>
        <p>Elevate your space and captivate with our bespoke creations. Dare to dream; we'll forge it into a reality that exceeds expectations.</p>
        <p className='gateCallofAction'>Ready to transform your balcony and windows into captivating focal points? </p>
        <p>🌟 Contact us today,   or <br />
        <button className="quoteButton" onClick={handleRequestQuote}>
            Request Quote
          </button>.
          </p>
        </div>
      </div>
      <GrillsGallery />
      <CallTodayButton />
    </div>
  );
};

export default GrillsPage;
