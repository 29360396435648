import React from 'react';
import oscarWeldingImage from '../../../Assets/Images/oscaraboutpage.jpg';
import './AboutPage.css'; // Import your CSS file for styling
import AboutGallery from '../AboutGallery/AboutProject';

const AboutPage = () => {
  return (
    <div className="about-container">
      <h1>About Oscar Welding KE</h1>
      <div className="about-content">
        <img src={oscarWeldingImage} alt="Oscar Welding KE" className="about-image" />
        <div className="about-description">
          <p>
            At Oscar Welding KE, we specialize in designing and crafting high-quality doors, windows, stairs, grills,
            and gates. We ensure superior craftsmanship with professional finishes. Trust us for all your welding needs.
          </p>
          <p>Our team comprises highly skilled technicians whose passion and expertise bring visions to life, transforming metal into mesmerizing windows that speak volumes.
          </p>
          <p>Your vision is our blueprint! Whether you seek intricate patterns or sleek modern designs, we bring your imagination to life with finesse.</p>
          <p>Dare to dream; 🌟 we'll forge it into a reality that exceeds expectations.</p>
          <p>
            We are located at Zimmerman, Nairobi. Visit us for exceptional welding solutions.
          </p>
        </div>
      </div>
      <AboutGallery />
    </div>
  );
};

export default AboutPage;
