// AboutSection.jsx
import React from 'react';
import './aboutSection.css'; 
import weldingImage from '../../Assets/Images/oscarAbout1.jpg';

const AboutSection = () => {
  return (
    <div className="aboutSection">
      <div className="aboutCard">
        <div className="aboutText">
          <h2>About Us</h2>
          <p>
          At Oscar Welding KE, we specialize in designing and crafting high-quality doors, windows, stairs, grills, and gates. 
          We ensure superior craftsmanship with professional finishes. Trust us for all your welding needs.
          </p>
 
          <p>Your vision is our blueprint! Whether you seek intricate patterns or sleek modern designs, we bring your imagination to life with finesse.</p>
          <p>Dare to dream; we'll forge it into a reality that exceeds expectations.</p>
          <p>
          We are located at Zimmerman, Nairobi. Visit us for exceptional welding solutions.
        </p>
          <a href="/about-us" className="read-moreButton">Read More</a>
        </div>
        <div className="about-usImage">
          <img src={weldingImage} alt="About Us" /> 
        </div>
      </div>
    </div>
  );
};

export default AboutSection;

