import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import { FiUser, FiMail, FiPhone, FiMapPin, FiMessageSquare, FiSend } from 'react-icons/fi';
import whatsappIcon from '../../../Assets/Logos/whatsappIcon.png';
import './ContactSection.css'; // Your CSS file for styling

emailjs.init("0D8Cc1oiv9lQkBwia");  

const ContactSection = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phoneNumber: '',
    subject: '',
    message: '',
  });

  const [errors, setErrors] = useState({});
  const [submissionStatus, setSubmissionStatus] = useState(null);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const [loading, setLoading] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();

    const validationErrors = {};
    if (!formData.name.trim()) {
      validationErrors.name = 'Name is required';
    }
    if (!formData.email.trim()) {
      validationErrors.email = 'Email is required';
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email)) {
      validationErrors.email = 'Invalid email format';
    }
    if (!formData.phoneNumber.trim()) {
      validationErrors.phoneNumber = 'Phone Number is required';
    } else if (!/^\d{10}$/.test(formData.phoneNumber)) {
      validationErrors.phoneNumber = 'Invalid phone number format (should be 10 digits)';
    }
    if (!formData.subject.trim()) {
      validationErrors.subject = 'Subject is required';
    }
    if (!formData.message.trim()) {
      validationErrors.message = 'Message is required';
    }

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      setSubmissionStatus('error');
      return;
    }

    // Clear errors on successful form submission
    setErrors({});
    setSubmissionStatus(null);

    setLoading(true); // Set loading state to true

    // Send email using Email.js
    emailjs.send("service_nnp52um", "template_gk08egh", {
      name: formData.name,
      email: formData.email,
      phoneNumber: formData.phoneNumber,
      subject: formData.subject,
      message: formData.message,
    }).then(
      function(response) {
        console.log("Email sent successfully", response);
        setSubmissionStatus('success');
        // Display success message using a pop-up or any other method
        window.alert("Message sent successfully!");
      },
      function(error) {
        console.log("Email failed to send", error);
        setSubmissionStatus('error');
        // Display error message using a pop-up or any other method
        window.alert("Error sending message. Please try again later.");
      }
    ).finally(() => {
      setLoading(false); // Set loading state to false after submission
    });
    // Reset form after submission
    setFormData({ name: '', email: '', phoneNumber: '', subject: '', message: '' });
  };


  const handleWhatsAppClick = () => {
    // Logic to open WhatsApp for chat
    // Replace '123456789' with your WhatsApp number
    window.open('https://wa.me/+254707460766', '_blank');
  };

  return (
    <div className="form1contactSection">
        <h2 className="form1H2">Contact Us</h2>
     <div className="grid-Form1container">
      <div className="contact-info">
        <h2>Our Contacts If you have any enquiries.</h2>
        <p> </p>
        <div className="contact-details">
          <div className="contact-item">
            <FiPhone className="Form1Icon"/> +254707460766
          </div>
          <div className="contact-item">
            <FiMail className="Form1Icon"/> info@oscarweldingke.co.ke
          </div>
          <div className="contact-item">
            <FiMapPin className="Form1Icon"/> Zimmerman, Nairobi.
          </div>
          <br />
          <div className="whatsapp1icon" onClick={handleWhatsAppClick}>
          <h2>You can also get in with us on our official WhatsApp.</h2>
            {/* WhatsApp icon */}
            <img src={whatsappIcon} alt="WhatsApp" />
          </div>
        </div>
      </div>

      <div className="contactForm1">
        <h2>Please fill-up the form below, and we will get back to you.</h2>
        <form onSubmit={handleSubmit}>
          <div className="form1-input-group">
            <FiUser className="Form1Icon" />
            <input
              type="text"
              name="name"
              value={formData.name}
              onChange={handleInputChange}
              placeholder="Your Name *"
              required
            />
            {errors.name && <span className="error-message">{errors.name}</span>}
          </div>
          <div className="form1-input-group">
            <FiMail className="Form1Icon"/>
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleInputChange}
              placeholder="Your Email *"
              required
            />
            {errors.email && <span className="error-message">{errors.email}</span>}
          </div>

          <div className="form1-input-group">
          <FiPhone className="Form1Icon" />
          <input
            type="tel"
            name="phoneNumber"
            value={formData.phoneNumber}
            onChange={handleInputChange}
            placeholder="Your Phone Number *"
            required
          />
          {errors.phoneNumber && <span className="error-message">{errors.phoneNumber}</span>}
        </div>

          <div className="form1-input-group">
            <FiMessageSquare className="Form1Icon" />
            <input
              type="text"
              name="subject"
              value={formData.subject}
              onChange={handleInputChange}
              placeholder="Subject *"
              required
            />
            {errors.subject && <span className="error-message">{errors.subject}</span>}
          </div>
          <div className="form1-input-group">
            <FiMessageSquare className="Form1Icon" />
            <textarea
              name="message"
              value={formData.message}
              onChange={handleInputChange}
              placeholder="Your Message *"
              required
            ></textarea>
            {errors.message && <span className="error-message">{errors.message}</span>}
          </div>
            <button className="form1Button" type="submit" disabled={loading}>
              {loading ? 'Sending...' : (
                <>
                  <span>Send Message</span>
                  <FiSend className="Form1Icon" />
                </>
              )}
            </button>

            {submissionStatus === 'success' && (
              <p className="success-message">Message sent successfully!</p>
            )}
            {submissionStatus === 'error' && (
              <p className="error-message">Error sending message. Please try again later.</p>
            )}
          
        </form>
      </div>
    </div>
 </div>
  );
};

export default ContactSection;
