import React from 'react';
import oscarGateImage from '../../../Assets/Images/gate.jpg';
import './GatesPage.css'; 
import GatesGallery from './GatesGallery';
import CallTodayButton from '../CallToday/CallTodayButton';

const GatesPage = () => {
  const handleRequestQuote = () => {
    const defaultMessage = encodeURIComponent(
      `Hello Oscar Welding KE,\n\n` +
      `I am interested in your welding services. ` +
      `Could you please provide a quote for the following:\n\n` +
      `- ***type service here***\n\n` +
      `- ***attach design images if any***\n\n` +
      `- \n\n` +
      `This message is being sent from https://oscarweldingke.co.ke/`
    );
  
    const whatsappLink = `https://wa.me/+254707460766/?text=${defaultMessage}`;

    // Open WhatsApp chat in a new tab
    window.open(whatsappLink, '_blank');
  };

  return (
    <div className="gatesContainer">
      <h1>Crafted Gates That Define Excellence</h1>
      <p>Welcome to Oscar Welding KE, where function meets finesse...</p>
      {/* Include the provided content for gates */}
      <div className="gatesContent">
      <img src={oscarGateImage} alt="Oscar Welding KE Doors" className="gatesImage" />
        <div className="gatesDescription">
        <p>Each gate we design and weld is a testament to bespoke artistry. From ornate designs to sleek modern lines, we curate gates that harmonize seamlessly with your property's allure.</p>
        <p>We settle for nothing less than excellence. Using top-tier materials, our gates stand as guardians of your property, offering both durability and timeless elegance.</p>
        <p>Elevate your property's security without sacrificing aesthetics. Our gates marry robust protection with refined design, promising both peace of mind and visual splendor.</p>
        <p>Beyond functionality, our gates transform mere entrances into grand statements, setting the tone for the sophistication and style within.</p> 
        <br />
        <p className='gateCallofAction'>Ready to elevate your property's allure with a gate that's more than an entrance—a work of art? </p>
        <p>🌟 Contact us today,   or <br />
        <button className="quoteButton" onClick={handleRequestQuote}>
            Request Quote
          </button>
          </p>
        </div>
      </div>
      <GatesGallery />
      <CallTodayButton />
    </div>
  );
};

export default GatesPage;
