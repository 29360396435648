import React from 'react';
import { Routes, Route } from "react-router-dom";
import Navbar from './Components/navigationBarSection/navigationBar';
import Home from './landingpage';
import './App.css'; 
import Footer from './Components/FooterSection/footerSection';
import ContactSection from './Components/pages/ContactForm/ContactForm';
import AboutPage from './Components/pages/aboutPage/AboutPage';
import GrillsPage from './Components/pages/GrillsPage/GrillsPaje';
import DoorsPage from './Components/pages/DoorsPage/DoorsPage';
import StairsPage from './Components/pages/StairsPage/StairsPage';
import WindowsPage from './Components/pages/WindowsPage/WindowsPage';
import GatesPage from './Components/pages/GatesPage/GatesPage';

const App = () => {
  return (
      <div className="App">

          <Navbar /> 
          <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/about-us" element={<AboutPage />} />
                <Route path="/contact-us" element={<ContactSection />} />

                <Route path="/doors-page" element={<DoorsPage />} />
                <Route path="/windows-page" element={<WindowsPage />} />
                <Route path="/grills-page" element={<GrillsPage />} />
                <Route path="/stairs-page" element={<StairsPage />} />
                <Route path="/gates-page" element={<GatesPage />} />

          
          </Routes>
          <Footer />
      
          </ div>
      
      
  );
}


export default App;
