import React from 'react';
import videoSource from '../../Assets/Videos/OscarVid2.mp4';
import './Hero.css';

const Hero = () => {
  const handleRequestQuote = () => {
    const defaultMessage = encodeURIComponent(
      `Hello Oscar Welding KE,\n\n` +
      `I am interested in your welding services. ` +
      `Could you please provide a quote for the following:\n\n` +
      `- ***type service here***\n\n` +
      `- ***attach design images if any***\n\n` +
      `- \n\n` +
      `This message is being sent from https://oscarweldingke.co.ke/`
    );
  
    const whatsappLink = `https://wa.me/+254707460766/?text=${defaultMessage}`;

    // Open WhatsApp chat in a new tab
    window.open(whatsappLink, '_blank');
  };

  return (
    <div className="heroContainer">
      <video autoPlay loop muted className="videoBackground">
        <source src={videoSource} type="video/mp4" alt="Video"/>
        Your browser does not support the video tag.
      </video>

      <div className="heroContent">
        <div className="heroText">
          <h1>
            Expert Welding <br />
            <span className="solutionText">Solution for All</span>
          </h1>
          <button className="quoteButton1" onClick={handleRequestQuote}>
            Request Quote
          </button>
        </div>
      </div>
    </div>
  );
};

export default Hero;
