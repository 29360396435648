import React from 'react';
import { Link } from 'react-router-dom';
import { FaFacebook, FaTiktok, FaYoutube } from 'react-icons/fa';
import logo from '../../Assets/Logos/oscar5.png';
import './Footer.css'; 

const Footer = () => {
  // Get the current year for the footer
  const currentYear = new Date().getFullYear();

  return (
    <footer className="footer">
      <div className="footer-column">
          <div className="footer-logo">
            <a href="/" title="Go to Home">
                <img src={logo} alt="Logo" className="logo-img" />
            </a>
            </div>
        <p className="footer-caption">
          Expert welding solutions for residential and commercial premises.
        </p>
      </div>
      <div className="footer-column">
        <h4>Services</h4>
        <ul className="services-list">
          <li><Link to="/doors-page">Doors</Link></li>
          <li><Link to="/windows-page">Windows</Link></li>
          <li><Link to="/gates-page">Gates</Link></li>
          <li><Link to="/stairs-page">Stairs</Link></li>
          <li><Link to="/grills-page">Grills</Link></li>
        </ul>
      </div>
      <div className="footer-column">
        <h4>Location</h4>
        <p>Zimmerman, Nairobi</p>

        <div className="social-icons">
        <Link to="https://web.facebook.com/oscarweldingke" target="_blank" title="Facebook Page">
            <FaFacebook />
          </Link>
          <FaTiktok />
          <FaYoutube />
        </div>
      </div>

      {/* Horizontal Line */}
      <hr className="footer-line" />
      <p className="footer-copyright">© {currentYear} Oscar Welding KE</p>
    </footer>
  );
};

export default Footer;
