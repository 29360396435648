

const MenuItems = () => {
  const menu = [
    {
      title: 'Home',
      link: '/'
    },
    {
      title: 'About',
      link: "/about-us",
    },
    {
      title: 'Services',
      link: '#',
      submenu: [
        { title: 'Doors', link: "/doors-page" },
        { title: 'Windows', link: "/windows-page" },
        { title: 'Grills', link: "/grills-page" },
        { title: 'Stairs', link: "/stairs-page" },
        { title: 'Gate', link: "/gates-page" }
      ]
    },
    {
      title: 'Contact',
      link: "/contact-us",
    }
  ];

  return menu;
};

export default MenuItems;
