import Hero from './Components/HeroSection/Heroo';
import AboutSection from './Components/AboutSection/aboutSection';
import ServicesSection from './Components/ServicesSection/servicesSection';
import WhyUsSection from './Components/WhyUsSection/whyusSection';
import CallTodayButton from './Components/pages/CallToday/CallTodayButton';
import ScrollToTopButton from './Components/pages/ScrollToTopButton';
import EnquireNowSection from './Components/EnquireNowSection/enquireNowSection';

const Home = () => {
    return (
        <>
          <Hero />
          <AboutSection />
          <ServicesSection />
          <WhyUsSection />
          <CallTodayButton />
          <ScrollToTopButton />
          <EnquireNowSection />

        </>
    );
}

export default Home;