import React, { useState, useRef } from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { FaDownload, FaHeart, FaFacebook, FaWhatsapp } from 'react-icons/fa';
import { FacebookShareButton, WhatsappShareButton } from 'react-share';
import './gateGallery.css';

import Image3 from '../../../Assets/Images/gates/gate01.png';
import Image4 from '../../../Assets/Images/gates/gate03.png';
import Image5 from '../../../Assets/Images/gates/gate04.png';
import Image6 from '../../../Assets/Images/gates/gate08.png';
import Image7 from '../../../Assets/Images/gates/gatework.png';
import Image8 from '../../../Assets/Images/gates/slidinggate01.png';
import Image9 from '../../../Assets/Images/gates/slidinggate02.png';

import Image1 from '../../../Assets/Images/gate2.jpg';
import Image2 from '../../../Assets/Images/gate3.jpg';

const GatesGallery = () => {
  const images = [Image1, Image2, Image3, Image4, Image5, Image6, Image7, Image8, Image9];

  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [liked, setLiked] = useState(Array(images.length).fill(false));
  const carouselRef = useRef(null);

  const handleLike = () => {
    const updatedLiked = [...liked];
    updatedLiked[currentImageIndex] = !updatedLiked[currentImageIndex];
    setLiked(updatedLiked);
  };

  const handleDownload = () => {
    const link = document.createElement('a');
    link.href = images[currentImageIndex];
    link.download = `project_${currentImageIndex + 1}.jpg`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleSocialShare = (platform) => {
    const baseUrl = window.location.origin; // Use the current origin
    const currentImage = images[currentImageIndex];
    const imageDirectUrl = `${baseUrl}${process.env.PUBLIC_URL}/assets/static/media/${currentImage}`;
  
    const pageUrl = window.location.href;
  
    if (platform === 'facebook') {
      return (
        <FacebookShareButton url={imageDirectUrl} quote="Check out this image!">
          <FaFacebook className='galleryIcon' color="blue" />
        </FacebookShareButton>
      );
    } else if (platform === 'whatsapp') {
      return (
        <WhatsappShareButton url={imageDirectUrl} title="Check out this image!">
          <FaWhatsapp className='galleryIcon' color="green" />
        </WhatsappShareButton>
      );
    }
  };
  

  return (
    <div className="gallery1Container">
      <h1>Projects' Gallery</h1>
      <div className="carousel1GateContainer" ref={carouselRef}>
        <Carousel
          selectedItem={currentImageIndex}
          onChange={(index) => setCurrentImageIndex(index)}
          showThumbs={false}
          dynamicHeight={true}
          infiniteLoop={true}
          autoPlay={true}
          interval={5000}
          stopOnHover={true}
          showStatus={false}
          showArrows={true}
          emulateTouch={true}
          swipeable={true}
        >
          {images.map((image, index) => (
            <div className="carouselimg" key={index}>
              <img src={image} alt={`Project ${index + 1}`} />
            </div>
          ))}
        </Carousel>
        <div className="image1-actions">
          <div className='galleryIcon2'>
            <FaHeart onClick={handleLike} color={liked[currentImageIndex] ? 'red' : 'black'} />{' '}
            {liked[currentImageIndex] ? 'Favorited' : 'Favorite'}
          </div>
          <div className='galleryIcon2'>
            <FaFacebook onClick={() => handleSocialShare('facebook')} className='galleryIcon' color="blue" />
          </div>
          <div className='galleryIcon2'>
            <FaWhatsapp onClick={() => handleSocialShare('whatsapp')} className='galleryIcon' color="green" />
          </div>
          <div className='galleryIcon2'>
            <FaDownload onClick={handleDownload} className='galleryIcon2' /> Download
          </div>
        </div>
      </div>
    </div>
  );
};

export default GatesGallery;
