import React, { useEffect, useState } from 'react';
import './WhyUsSection.css'; 

const WhyUsSection = () => {

    const [clientCount, setClientCount] = useState(0);

    useEffect(() => {
      const interval = setInterval(() => {
        if (clientCount < 1000) {
          setClientCount((prevCount) => prevCount + 1);
        } else {
          clearInterval(interval);
        }
      }, 5); // Adjust the speed of counting here
  
      // Clear interval on unmount to prevent memory leaks
      return () => clearInterval(interval);
    }, [clientCount])

  return (
    <div className="whySection">
      <div className="whyCard">
        <div className="whyText1">
          <h2>Catering All Your Welding Requirements With Excellence</h2>
        </div>
      </div>
      <div className="whyCard">
        <div className="whyText">
          <h2>We believe in offering the highest quality welding solutions</h2>
          <p>
            to meet your doors, windows, stairs, grills, and gates needs.
            Our commitment to quality is unwavering.
          </p>
          <p className="CountP">{clientCount}+ Happy Clients</p>
        </div>
      </div>
    </div>
  );
};

export default WhyUsSection;
