/* ServicesSection.jsx */

import React from 'react';
import { Link } from 'react-router-dom';
import doorImage from '../../Assets/Images/Door 1.jpg';
import windowImage from '../../Assets/Images/window2.jpg';
import stairImage from '../../Assets/Images/stairs3.jpg';
import grillsImage from '../../Assets/Images/balconygriil1.jpg';
import gateImage from '../../Assets/Images/gate3.jpg';
// Import other images similarly

import './ServicesSection.css'; // CSS file for styling

const ServicesSection = () => {
  const services = [
    {
        id: 1,
        title: 'Get Custom, Handcrafted Welded Doors for Your Homes',
        description: 'Every door at Oscar Welding KE is a testament to our commitment to exceptional quality, stringent durability, and meticulous craftsmanship. We take pride in our ability to exceed our clients’ expectations.',
        image: doorImage,
        path: '/doors-page',
      },
      {
        id: 2,
        title: 'Creating Functional, Beautiful Welded Windows with Precision',
        description: 'We specialize in creating beautifully designed, highly functional welded windows that bring style and security to your home. We use only the highest quality materials and our technicians are highly skilled.',
        image: windowImage,
        path: "/windows-page",
      },
      {
        id: 3,
        title: 'Providing Robust, Attractive Welded Stairs Solutions for You',
        description: 'Our stairs are designed with a unique blend of style, safety, and strength. Individually handcrafted by our experienced welders, every staircase we create is made to impress and built to last.',
        image: stairImage,
        path:"/stairs-page",
      },
      {
        id: 4,
        title: 'Balcony & Window Grills',
        description: 'Crafting Exceptional Balcony & Window Grills Welding Solutions: Elevate Your Space with Secure Elegance.',
        image: grillsImage,
        path:"/grills-page",
      },
      {
        id: 5,
        title: 'Gates',
        description: 'Gateway to Elegance: Crafted Gates That Define Excellence. Secure your premises with solid gates.',
        image: gateImage,
        path:"/gates-page",
      },
    // Add other services similarly
  ];

  const renderServices = () => {
    return services.map((service) => (
      <div key={service.id} className="flip-card">
        <div className="flip-card-inner">
          <div className="flip-card-front">
            <img src={service.image} alt={service.title} />
            <h3>{service.title}</h3>
          </div>
          <div className="flip-card-back">
            <h3>{service.title}</h3>
            <p>{service.description}</p>
            <Link to={service.path} className="view-button">View Details</Link>
          </div>
        </div>
      </div>
    ));
  };

  return <div className="services-section">{renderServices()}</div>;
};

export default ServicesSection;