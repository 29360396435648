import React from 'react';
import oscarDoorsImage from '../../../Assets/Images/doors/door09.png';
import DoorGallery from '../DoorsPage/DoorGallery';
import './DoorPage.css'; // Import your CSS file for styling
import CallTodayButton from '../CallToday/CallTodayButton';

const DoorsPage = () => {

  const handleRequestQuote = () => {
    const defaultMessage = encodeURIComponent(
      `Hello Oscar Welding KE,\n\n` +
      `I am interested in your welding services. ` +
      `Could you please provide a quote for the following:\n\n` +
      `- ***type service here***\n\n` +
      `- ***attach design images if any***\n\n` +
      `- \n\n` +
      `This message is being sent from https://oscarweldingke.co.ke/`
    );
  
    const whatsappLink = `https://wa.me/+254707460766/?text=${defaultMessage}`;

    // Open WhatsApp chat in a new tab
    window.open(whatsappLink, '_blank');
  };

  return (
    <div className="doorsContainer">
      <h1>Get Custom, Handcrafted Welded Doors for Your Homes</h1>
      <div className="doorsContent">
        <img src={oscarDoorsImage} alt="Oscar Welding KE Doors" className="doorsImage" />
        <div className="doorsDescription">
          <p>
            Every door at Oscar Welding KE is a testament to our commitment to exceptional quality, stringent durability, and meticulous craftsmanship. We take pride in our ability to exceed our clients’ expectations.
          </p>
          <p>
            Our doors are crafted with precision and care, ensuring not just functionality but also an aesthetic appeal that enhances the beauty of your home or space.
          </p>
          <p>
            Whether you need customized designs, specific sizes, or unique finishes, our team at Oscar Welding KE is dedicated to delivering doors that resonate with your vision and needs.
          </p>
          <p className='gateCallofAction'>
            Visit us today to explore our wide range of door designs and let us assist you in finding the perfect fit for your property.
          </p>
        <p>🌟 Contact us today,   or <br />
        <button className="quoteButton" onClick={handleRequestQuote}>
            Request Quote
          </button>
          </p>
        </div>
      </div>
      <DoorGallery />
      <CallTodayButton />
      
      {/* Additional components or content related to doors */}
    </div>
  );
};

export default DoorsPage;
