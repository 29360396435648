import React from 'react';
import './EnquireNowSection.css'; // Import your CSS file for styling

const EnquireNowSection = () => {
  const handleRequestQuote = () => {
    const defaultMessage = encodeURIComponent(
      `Hello Oscar Welding KE,\n\n` +
      `I am interested in your welding services. ` +
      `Could you please provide a quote for the following:\n\n` +
      `- ***type service here***\n\n` +
      `- ***attach design images if any***\n\n` +
      `- \n\n` +
      `This message is being sent from https://oscarweldingke.co.ke/`
    );
  
    const whatsappLink = `https://wa.me/+254707460766/?text=${defaultMessage}`;

    // Open WhatsApp chat in a new tab
    window.open(whatsappLink, '_blank');
  };

  return (
    <div className="enquireCard">
      <h2>Excited to Start a Project with Us?</h2>
      <p>Get in touch with our team today and receive a free quote on all our welding services.</p>
      <form className="enquireForm">    
      <button className="enquireFormButton" onClick={handleRequestQuote}>
            Request Quote
          </button>.
      </form>
    </div>
  );
};

export default EnquireNowSection;
