import React, { useState } from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import {  FaDownload } from 'react-icons/fa';
import { FaHeart} from 'react-icons/fa';
import { FacebookShareButton, WhatsappShareButton } from 'react-share';
import { FaFacebook, FaWhatsapp } from 'react-icons/fa';
// import './aboutGallery.css';

import Image1 from '../../../Assets/Images/grills1.jpg';
import Image2 from '../../../Assets/Images/grills.jpg';
import Image3 from '../../../Assets/Images/balconygrill2.jpg';
import Image4 from '../../../Assets/Images/balconygriil1.jpg';
import Image5 from '../../../Assets/Images/grill3.jpg';
import Image6 from '../../../Assets/Images/grill4.jpg';

// Add more imports for your images as needed

const GrillsGallery = () => {
  const images = [
    Image1,
    Image2,
    Image3,
    Image5,
    Image6,
    Image4,
    
    // Add more images imported above as needed
  ];


  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [liked, setLiked] = useState(Array(images.length).fill(false));

  const handleLike = () => {
    const updatedLiked = [...liked];
    updatedLiked[currentImageIndex] = !updatedLiked[currentImageIndex];
    setLiked(updatedLiked);
  };

  const handleDownload = () => {
    const link = document.createElement('a');
    link.href = images[currentImageIndex];
    link.download = `project_${currentImageIndex + 1}.jpg`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleSocialShare = (platform) => {
    const baseUrl = window.location.origin; // Use the current origin
    const currentImage = images[currentImageIndex];
    const imageDirectUrl = `${baseUrl}${process.env.PUBLIC_URL}/assets/static/media/${currentImage}`;
  
    const pageUrl = window.location.href;
  
    if (platform === 'facebook') {
      return (
        <FacebookShareButton url={imageDirectUrl} quote="Check out this image!">
          <FaFacebook className='galleryIcon' color="blue" />
        </FacebookShareButton>
      );
    } else if (platform === 'whatsapp') {
      return (
        <WhatsappShareButton url={imageDirectUrl} title="Check out this image!">
          <FaWhatsapp className='galleryIcon' color="green" />
        </WhatsappShareButton>
      );
    }
  };
  

  

  return (
    <div className="gallery1Container">
      <h1>Projects' Gallery</h1>
      <div className="carousel1GateContainer">
        <Carousel
            //Carousel props...
          selectedItem={currentImageIndex}
          onChange={(index) => setCurrentImageIndex(index)}
          showThumbs={false} // Hide the thumbnail images
          dynamicHeight={true} // Adjust carousel height based on the content
          infiniteLoop={true} // Enable infinite loop of slides
          autoPlay={true} // Enable autoplay
          interval={5000} // Set autoplay interval to 5 seconds (in milliseconds)
          stopOnHover={true} // Stop autoplay on hover
          showStatus={false} // Hide the status indicator (pagination)
          showArrows={true} // Show navigation arrows
          emulateTouch={true} // Enable swipe gestures on touch devices
          swipeable={true} // Enable swiping through slides
        >
          {images.map((image, index) => (
            <div className="carousel1Containerimg" key={index}>
              <img src={image} alt={`Project ${index + 1}`} />
            </div>
          ))}
        </Carousel>
        <div className="image1-actions">
       
        <div className='galleryIcon2'>
        <FaHeart  onClick={handleLike} color={liked[currentImageIndex] ? 'red' : 'black'} />{' '}
        {liked[currentImageIndex] ? 'Favorited' : 'Favorite'}
        </div>

       {handleSocialShare('facebook', images[currentImageIndex])}
      {handleSocialShare('whatsapp', images[currentImageIndex])}

            <div className='galleryIcon2'>
            <FaDownload onClick={handleDownload} className='galleryIcon2' /> Download
            </div>
        </div>
      </div>
    </div>
  );
};

export default GrillsGallery;

