import React, { useState, useEffect, useRef } from 'react';
import MenuItems from './menuItems';
import logo from '../../Assets/Logos/oscar5.png';
import './NavigationBar.css'; // Import the CSS file

const NavigationBar = () => {
  const [showSubMenu, setShowSubMenu] = useState(false);
  const [isNavVisible, setIsNavVisible] = useState(false);
  const navRef = useRef(null);

  const handleSubMenuToggle = () => {
    setShowSubMenu(!showSubMenu);
  };

  const handleNavToggle = () => {
    setIsNavVisible(!isNavVisible);
  };

  const handleCloseNav = (e) => {
    if (navRef.current && !navRef.current.contains(e.target)) {
      setIsNavVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleCloseNav);
    return () => {
      document.removeEventListener('mousedown', handleCloseNav);
    };
  }, []);

  // JavaScript to close the open menu on scroll
window.addEventListener('scroll', function() {
  const menu = document.querySelector('.menu');
  const hamburger = document.querySelector('.hamburger');

  if (menu.classList.contains('open')) {
    menu.classList.remove('open');
    hamburger.classList.remove('hide'); // Show hamburger lines again
  }
});


  const renderSubMenu = (submenu) => {
    if (showSubMenu) {
      return (
        <ul className="submenu">
          {submenu.map((item, index) => (
            <li key={index} className="submenu-item">
              <a href={item.link} className="submenu-link">{item.title}</a>
            </li>
          ))}
        </ul>
      );
    }
    return null;
  };

  return (
   <nav className="navbar" ref={navRef}>
      <div className="logo">
          <a href="/" title="Go to Home">
            <img src={logo} alt="Logo" className="logo-img" />
          </a>
      </div>

      <div className="hamburger" onClick={handleNavToggle}>
        <div className="line"></div>
        <div className="line"></div>
        <div className="line"></div>
      </div>
      
      {/* {isNavVisible && (
        <AiOutlineClose className="close-icon" onClick={() => setIsNavVisible(false)} />
      )} */}

      <ul className={`menu ${isNavVisible ? 'open' : ''}`}>
        {MenuItems().map((item, index) => (
          <li
            key={index}
            className={item.title === 'Services' ? 'menu-item has-submenu' : 'menu-item'}
            onMouseEnter={() => item.title === 'Services' && handleSubMenuToggle()}
            onMouseLeave={() => item.title === 'Services' && handleSubMenuToggle()}
          >
            <a href={item.link} className="menu-link">
              {item.title}
              {/* {item.title === 'Services' && <AiOutlineDown />}  */}
            </a>
            {item.title === 'Services' && renderSubMenu(item.submenu)}
          </li>
        ))}
      </ul>
    </nav>
  );
};

export default NavigationBar;
