import React from 'react';
import oscarWeldingImage from '../../../Assets/Images/stairs6.jpg';
import StairsGallery from '../StairsPage/StairsGallery';
import './StairsPage.css'; 
import CallTodayButton from '../CallToday/CallTodayButton';

const StairsPage = () => {
  const handleRequestQuote = () => {
    const defaultMessage = encodeURIComponent(
      `Hello Oscar Welding KE,\n\n` +
      `I am interested in your welding services. ` +
      `Could you please provide a quote for the following:\n\n` +
      `- ***type service here***\n\n` +
      `- ***attach design images if any***\n\n` +
      `- \n\n` +
      `This message is being sent from https://oscarweldingke.co.ke/`
    );
  
    const whatsappLink = `https://wa.me/+254707460766/?text=${defaultMessage}`;

    // Open WhatsApp chat in a new tab
    window.open(whatsappLink, '_blank');
  };

  return (
    <div className="stairsContainer">
      <h1>Bespoke Welded Stairs for Safety and Sophistication</h1>
      <p>At Oscar Welding KE, we redefine staircases as more than mere transitions; they're architectural marvels meticulously crafted to elevate your space.</p>
      {/* Include the provided content for stairs */}
      <div className="stairsContent">
        <img src={oscarWeldingImage} alt="Oscar Welding KE" className="grillImage" />
        <div className="StairsDescription">
          <p>Our stairs embody a seamless blend of style, safety, and robustness. Each step is an artistic creation, meticulously handcrafted to leave a lasting impression.</p>
          <p>Crafted by seasoned welders and artisans, every staircase we forge is a testament to precision and expertise, ensuring a seamless combination of beauty and resilience.</p>
          <p>We prioritize durability without compromising elegance. Utilizing premium materials and expert welding techniques, our stairs stand as a testament to unwavering strength.</p>
          <p>Your space deserves a staircase as unique as your style. From classic elegance to contemporary chic, our stairs are customized to mirror your vision and complement your aesthetic.</p>
          <p>Beyond their stunning appearance, our welded stairs prioritize safety, offering stability and security while serving as a striking centerpiece of your home.</p>
          <p>Our commitment extends beyond design; we craft staircases that endure, promising longevity and retaining their allure for generations to come.</p>
          <p className='gateCallofAction'>Ready to transform your space with stairs that redefine elegance and reliability?</p>
          <p>🌟 ✨ Reach out to us today,  <br />
          <button className="quoteButton" onClick={handleRequestQuote}>
            Request Quote
          </button>.</p>
        </div>
      </div>
      <StairsGallery />
      <CallTodayButton />
    </div>

  );
};

export default StairsPage;
