// CallTodayButton.js

import React from 'react';
import './CallTodayButton.css'; // Your CSS file for the button styles

const CallTodayButton = () => {
  const handleCallClick = () => {
    window.location.href = 'tel:+254707460766'; // Replace with your phone number
  };

  return (
    <div className="call-today">
      <a href="#" className="call-button" onClick={handleCallClick}>
      </a>
      <span className="callText">Call Today</span>
    </div>
  );
};

export default CallTodayButton; // Ensure you export the component correctly
